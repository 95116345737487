
import { base } from "../StakeMiner/score";
import Navbar from "@/components/Navbar/NavbarPure";
import DPRSlider from "@/components/DPRSlider/DPRSlider";
import "@vant/touch-emulator";
export default {
  components: {
    Navbar,
    DPRSlider,
  },
  data() {
    return {
      tabIndex: 0,
      show: false,
      level: 1,
      price: 0.0015,
    };
  },
  computed: {
    // scoreItem1() {
    //   let item = base2GenesisScore(this.level);
    //   let addAPR = item.addAPR;
    //   item.daily = ((item.value * addAPR) / 365).toFixed(2);
    //   item.dailyUsd = (item.daily * this.price).toFixed(2);

    //   item.monthly = (((item.value * addAPR) / 365) * 30).toFixed(2);
    //   item.monthlyUsd = (item.monthly * this.price).toFixed(2);

    //   item.yearly = (item.value * addAPR).toFixed(2);
    //   item.yearlyUsd = (item.yearly * this.price).toFixed(2);
    //   item.tabIndex = this.tabIndex;
    //   return item;
    // },
    // scoreItem2() {
    //   let item = base2GenesisScore(this.level);
    //   let addAPR = item.addAPR;
    //   item.daily = ((item.value * addAPR) / 365).toFixed(2);
    //   item.dailyUsd = (item.daily * this.price).toFixed(2);

    //   item.monthly = (((item.value * addAPR) / 365) * 30).toFixed(2);
    //   item.monthlyUsd = (item.monthly * this.price).toFixed(2);

    //   item.yearly = (item.value * addAPR).toFixed(2);
    //   item.yearlyUsd = (item.yearly * this.price).toFixed(2);
    //   item.tabIndex = this.tabIndex;
    //   return item;
    // },
    scoreItemBase() {
      let item = base.getScore(this.level);
      item.dailyUsd = (item.daily * this.price).toFixed(2);

      item.monthly = (item.daily*30).toFixed(2);
      item.monthlyUsd = (item.monthly * this.price).toFixed(2);

      item.yearly = (item.daily*365).toFixed(2);
      item.yearlyUsd = (item.yearly * this.price).toFixed(2);
      item.tabIndex = this.tabIndex;
      return item;
    },
  },
  methods: {
    t(key) {
      return this.$t(`calculator.${key}`);
    },
  },
};
